import React, { StrictMode, Suspense, FC, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider as GnosisThemeProvider, Loader } from "@epignosis_llc/gnosis";
import { ThemeProvider, SerializedStyles } from "@emotion/react";
import { I18nextProvider } from "react-i18next";
import { Query, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { Replay } from "@sentry/react";
import { Worker } from "@react-pdf-viewer/core";
import "focus-visible";
import Routes from "./Routes/Routes";
import { errorBoundaryHandler } from "@errors";
import { i18n } from "@utils/i18n";
import globalStyles from "@styles/global";
import { useUIStore } from "@stores";
import "./channels/notifications";
import { config, getBaseUrl } from "@config";
import { useAuth, usePrevious } from "@hooks";
import queryKeys from "@constants/queryKeys";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallbackComponent } from "@components/ReusableComponents";

const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://13b17cb9c5e149389fe4d93bdec0605a@o1417853.ingest.sentry.io/6776415",
  integrations: [new BrowserTracing(), new Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: config.isProd() ? "production" : "staging",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const App: FC = () => {
  const { isAuthenticated } = useAuth();
  const previousIsAuthenticated = usePrevious(isAuthenticated);
  const { theme } = useUIStore((state) => state);
  const { getCollapsedMainNav } = useUIStore();

  // set the preferred query client default settings
  queryClient.setDefaultOptions({
    queries: {
      retry: isAuthenticated ? 3 : false,
    },
  });

  // get main nav from locale storage
  useEffect(() => {
    getCollapsedMainNav();
  }, [getCollapsedMainNav]);

  // update the preferred query client default settings when isAuthenticated changes
  useEffect(() => {
    // cancel queries only when isAuthenticated changes from true to false
    if (previousIsAuthenticated && !isAuthenticated) {
      queryClient.cancelQueries({
        predicate: (query: Query): boolean => query.queryKey !== queryKeys.domainSettings,
      });
    }

    queryClient.setDefaultOptions({
      queries: {
        retry: isAuthenticated ? 3 : false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <StrictMode>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <GnosisThemeProvider
            theme={theme}
            globalStyles={globalStyles as unknown as SerializedStyles}
          >
            <Router basename={getBaseUrl()}>
              <ErrorBoundary
                FallbackComponent={ErrorFallbackComponent}
                onError={errorBoundaryHandler}
              >
                <Suspense fallback={<Loader fullScreen />}>
                  <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
                      <Routes />
                    </Worker>
                  </QueryClientProvider>
                </Suspense>
              </ErrorBoundary>
            </Router>
          </GnosisThemeProvider>
        </ThemeProvider>
      </I18nextProvider>
    </StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
